.Root {
  height: 100%;
  z-index: 0;
  padding: 0;

  .ColumnCheckbox {
    margin: 0;
    padding: 0;
    padding-inline-start: 4px;
  }
}

.Actions {
  padding: 10px 24px 8px;
  border-bottom: 2px solid #dcdcdc;
}

.ActionBtn {
  margin-right: 10px;
}

.ActionItem {
  color: black;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 6px 0;

  &:hover {
    color: black;
  }
}

.TableBtn {
  font-size: 12px;
}

.ChangePasswordModalForm {
  display: flex;
  flex-direction: column;
}

.Filter {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.FilterInput {
  width: 300px;
}

.Content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.DataTableContainer {
  height: 100%;
}

.BtnGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 150px);
  background-color: white;
  border-radius: 16px;
}

.Checkbox {
  margin: 0;
  //padding: 8px;
  padding: 11px 8px 10px 12px;
}
